import * as React from 'react'
import {FC, FormEvent, useEffect} from "react";
import './apr-modal.scss'

interface AprModalInterface {
    heading: string;
    onClose: (e: any) => any;
    onSubmit?: () => any;
    children: any;
    buttonSubmit?: string;
    buttonCancel?: string;
    disableCancel?: boolean;
    disableSubmit?: boolean;
}

const AprModal: FC<AprModalInterface> = ({heading, onSubmit, onClose, children, buttonCancel = 'Cancel', buttonSubmit = 'Submit', disableCancel, disableSubmit}) => {
    function onClickCloseModal(event: any) {
        onClose(event)
    }

    function onSubmitForm(event: FormEvent) {
        event.preventDefault()
        if (event.target === event.currentTarget) {
            if (onSubmit)
                onSubmit()
        }
    }

    useEffect(() => {
        document.onkeydown = (event) => {
            if ("key" in event) {
                if (event.key === 'Escape' || event.key === 'Esc') {
                    onClickCloseModal(event)
                }
            }
        }

        return () => {
            document.onkeydown = null
        }
    }, [])

    return <form onSubmit={onSubmitForm}>
        <div className={'apr-modal'}>
            <div className={'modal-content'}>
                <div className={'modal-heading'}>
                    <h1>{heading}</h1>
                    <button type="button" disabled={disableCancel} className={'close-modal'} onClick={onClickCloseModal}>X</button>
                </div>
                <div>
                    {children}
                </div>
                <div className={'modal-footer'}>
                    <button type="button" disabled={disableCancel} className={'back-button'} onClick={onClickCloseModal}>{buttonCancel}</button>
                    {onSubmit && <button type={'submit'} disabled={disableSubmit}>{buttonSubmit}</button>}
                </div>
            </div>
        </div>
    </form>
}

export default AprModal